<template>
  <transition name="fade">
    <div
      v-if="isShowing"
      class="BackDrop"
      @click="onClick"/>
  </transition>
</template>

<script>
export default {
  props: {
    isShowing: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    onClick() {
      this.$emit('back-drop-click')
    },
  },
}
</script>

<style lang="stylus" scoped>
.BackDrop
  position fixed
  top 0
  left 0
  z-index $z_backdrop
  width 100%
  height 100vh
  background-color rgba(0, 0, 0, 0.25)

.fade-enter-active, .fade-leave-active {
  transition opacity $menu_transition_time = 0.15s ease-in-out
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
