<template>
  <div class="Changelog">
    <Dialog
      :isShowing="showChangelog"
      :useComponent="ChangelogModal"
      :componentProps="{}"
      size="medium"
      @close="showChangelog = false" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Dialog from '@/components/Modals/Dialog.vue'
import ChangelogModal from '@/components/Changelog/ChangelogModal.vue'
import EventBus from '@/EventBus'
import { keys } from '@/globals/javascript/_util/keys'

export default {
  name: 'Changelog',
  data() {
    return {
      ChangelogModal,
      showChangelog: false,
    }
  },
  computed: {
    ...mapGetters([
      'latestChangelogVersion',
      'isDemoUser',
    ]),
  },
  methods: {
    onShowChangelog() {
      this.showChangelog = true
      if (this.latestChangelogVersion) {
        window.localStorage.setItem(
          keys.LS_LAST_SEEN_CHANGELOG_VERSION,
          this.latestChangelogVersion,
        )
      }
    },
    onCheckToShowChangelog() {
      if (!this.latestChangelogVersion) {
        return
      }

      if ((new URLSearchParams(window.location.search)).has('pdf')) {
        return
      }

      if (this.isDemoUser) {
        return
      }

      const lastSeenVersion = window.localStorage.getItem(keys.LS_LAST_SEEN_CHANGELOG_VERSION)
      const isNewerVersionAvailable = this.mixCompareVersionsNewerOnly(
        this.latestChangelogVersion,
        lastSeenVersion || '1.0.0',
      )

      if (!isNewerVersionAvailable) {
        return
      }

      this.onShowChangelog()
    },
  },
  components: {
    Dialog,
  },
  created() {
    EventBus.$on('show-change-log', this.onShowChangelog)
    EventBus.$on('check-to-show-changelog', this.onCheckToShowChangelog)
  },
  destroyed() {
    EventBus.$off('show-change-log', this.onShowChangelog)
    EventBus.$off('check-to-show-changelog', this.onCheckToShowChangelog)
  },
}
</script>

<style lang="stylus" scoped>
  .Changelog
    display block
</style>
