<template>
  <div class="FlagIcon">
    <DKFlag v-if="countryCode === 'DK'" />
    <ENFlag v-if="countryCode === 'EN'" />
  </div>
</template>

<script>
import DKFlag from '@/assets/flags/1x1/dk.svg?inline'
import ENFlag from '@/assets/flags/1x1/en.svg?inline'

export default {
  name: 'FlagIcon',
  props: {
    countryCode: {
      type: String,
      required: true,
    },
  },
  components: {
    DKFlag,
    ENFlag,
  },
}
</script>

<style lang="stylus" scoped>
  .FlagIcon
    display block

  svg
    box(100%)
</style>
