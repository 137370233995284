<template>
  <div>
    <!-- Backdrop -->
    <BackDrop
      @back-drop-click="onCloseSidePane"
      :is-showing="isShowing" />

    <!-- Sidepane -->
    <transition name="slide-in-left">
      <div
        v-if="isShowing"
        class="SidePane">
        <components
          :is="useComponent"
          @close-side-pane="onCloseSidePane" />
      </div>
    </transition>
  </div>
</template>

<script>
import BackDrop from '@/components/BackDrop'

export default {
  props: {
    useComponent: {
      type: Object,
      required: true,
    },
    isShowing: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    $route() {
      this.onCloseSidePane()
    },
  },
  methods: {
    onCloseSidePane() {
      if (this.isShowing) this.$emit('close-menu')
    },
  },
  components: {
    BackDrop,
  },
}
</script>

<style lang="stylus" scoped>
.SidePane
  position fixed
  top 0
  z-index $z_global_side-pane
  overflow auto
  box(80vw, 100%)
  max-width 320px
  background-color #fff
  box-shadow 0px 0px 8px rgba(0,0,0,0.6)
  smooth-scroll()

.slide-in-left-enter-active
  transition transform $menu_transition_time cubic-bezier(0.25, 0.46, 0.45, 0.94)

.slide-in-left-leave-active
  transition transform $menu_transition_time cubic-bezier(0.55, 0.085, 0.68, 0.53)

.slide-in-left-enter, .slide-in-left-leave-to
  transform translateX(-330px)
</style>
