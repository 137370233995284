import { HOST } from '@/globals/javascript/_util/host'
import {
  mixWB, mixGetDownloadURL, mixFormatPhoneNumber, mixWBBase,
} from '@/globals/javascript/_util/mixins'
import { ENV } from '@/globals/javascript/_util/env'
import moment from 'moment'

export const requisition = {
  state: {
    samplesList: [],
    options: {
      deliveryTime: null,
      laboratory: null,
    },
    states: {
      isCompleted: false,
      isSending: false,
      tableIsUpdating: false,
      hasUnsavedChanges: false,
    },
  },
  mutations: {
    setDeliveryTime: (state, data) => {
      state.options.deliveryTime = data
    },
    setLaboratory: (state, data) => {
      state.options.laboratory = data
    },
    setRequisitionCompleted: (state, bool) => {
      state.states.isCompleted = bool
    },
    setHasUnsaveChanges: (state, bool) => {
      state.states.hasUnsavedChanges = bool
    },
    removeSampleFromSelection: (state, sampleID) => {
      const sampleIndex = state.samplesList.findIndex((x) => x.sampleID === sampleID)

      state.samplesList.splice(sampleIndex, 1)
    },
    removeRequisitionData: (state) => {
      // Removes user selection + PDF send
      state.samplesList = []
    },
    setIsSending: (state, bool) => {
      state.states.isSending = bool
    },
    setTableIsUpdating: (state, bool) => {
      state.states.tableIsUpdating = bool
    },
    updateSample: (state, data) => {
      const { sampleID, analysesIDs } = data
      const sampleIndex = state.samplesList.findIndex((x) => x.sampleID === sampleID)

      // Sample context doesn't exist
      if (sampleIndex === -1) {
        state.samplesList.push(data)
        return
      }

      // Update with new list of IDs
      state.samplesList[sampleIndex].analysesIDs = analysesIDs
    },
  },
  actions: {
    addSelectableSamples: ({ commit }, samples) => {
      samples.forEach((sample) => {
        const item = {
          sampleID: sample.id,
          title: {
            selected: `${ sample.displayID } - ${ sample.title.selected }`,
            base: `${ sample.displayID } - ${ sample.title.base }`,
          },
          sampleNumber: sample.rawSample.sampleNumber,
          analysesIDs: [],
        }

        commit('updateSample', item)
      })
    },

    toogleRequisitionSamples: ({ commit }, { samples, bool }) => {
      // Ensure that the state is registred before starting the update
      commit('setTableIsUpdating', true)

      setTimeout(() => {
        samples.forEach((sample) => {
          if (sample.isCompleted) {
            return
          }

          if (!bool) {
            commit('updateSample', { sampleID: sample.id, analysesIDs: [] })
            return
          }

          const analysesIDs = sample.analyses.reduce((prev, analysis) => {
            if (analysis.missingResult) {
              prev.push(analysis.sampleTypeID)
            }

            return prev
          }, [])

          commit('updateSample', { sampleID: sample.id, analysesIDs })
        })

        commit('setTableIsUpdating', false)
      })
    },
    updateSample: ({ commit }, data) => {
      commit('updateSample', data)
    },
    resetRequisitionFlow: ({ commit }) => {
      commit('removeRequisitionData')
    },

    setIsSendingRequisition: ({ commit }, bool) => {
      commit('setIsSending', bool)
    },
    setHasUnsaveChanges: ({ commit }, bool) => {
      commit('setHasUnsaveChanges', bool)
    },
    setDeliveryTime: ({ commit }, bool) => {
      commit('setDeliveryTime', bool)
    },
    setLaboratory: ({ commit }, bool) => {
      commit('setLaboratory', bool)
    },
    setRequisitionCompleted: ({ commit }, bool) => {
      commit('setRequisitionCompleted', bool)
    },

    generateSendDate: () => ({
      timeStamp: Date.now(),
      formatted: moment().locale('da-DK').format('ll'),
    }),
    processRequisition: async ({ dispatch, getters }, data) => {
      const {
        currentScreeningData,
        currentUser,
        currentAccount,
        caseNumber,

        requisitionOptions,
        requisitionSampleCount,
        requisitionAnalysisCount,
      } = getters

      const caseID = currentScreeningData.customCaseNumber || caseNumber
      const dates = await dispatch('generateSendDate')
      let accountLogo = ''

      try {
        accountLogo = await mixGetDownloadURL({
          path: currentAccount.images.emailLogo || currentAccount.images.mainLogo,
        })
      }
      catch (e) {
        // accountLogo not found
        if (ENV.isStage || ENV.isDevelopment) {
          console.error('Account logo path not found in storage')
        }
      }

      // Check if pay per requisition is available AND selected
      const payPerRequisition = currentAccount.allowMilvaEuroFins
        && currentAccount.billPerRequisition
        && requisitionOptions.laboratory.id === 'milvaEuroFins'

      const bodyData = {
        projectID: data.projectID,
        sampleCount: requisitionSampleCount,
        analysisCount: requisitionAnalysisCount,
        htmlDocument: data.html,
        created: dates.timeStamp,
        projectExecutionYear: `${ currentScreeningData.screeningYear }`,
        projectExecutionMonth: `${ currentScreeningData.screeningMonth }`,

        storageName: `requisition-${ caseNumber }-${ dates.timeStamp }`,
        fileName: `Rekvisition - ${ caseID } - ${ dates.formatted }`,

        caseNumber: caseID,
        autoCaseNumber: caseNumber,
        prettyDelivery: requisitionOptions.deliveryTime.text,
        labEmail: requisitionOptions.laboratory.requisitionEmail,

        isDevTest: data.isDevTest,
        payPerRequisition,

        user: {
          id: currentUser.id,
          name: currentUser.fullName,
          email: currentUser.email,
          phone: currentUser.phoneNumber,
        },
        account: {
          name: currentAccount.name,
          logo: accountLogo,
          id: currentAccount.id,
        },
      }

      const response = await fetch(
        `${ HOST.cloudFunction }/pro_v2_requisitionPDF_gen2`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
          },
          body: JSON.stringify(bodyData),
        },
      )

      // Change requisition state to sent (when not a dev test)
      if (response.ok && !data.isDevTest) {
        dispatch('updateScreening', { setRequisitionSent: true })
      }

      return response
    },
  },
  getters: {
    requisitionSampleList: (state) => state.samplesList,
    requisitionUserSelections:
      (state) => state.samplesList.filter((x) => x.analysesIDs.length),
    isRequisitionFlowAvailable:
      (state, getters) => getters.screeningSamples.some((x) => x.sampleNumber),
    isRequisitionPreviewAvailable:
      (state, getters) => !!getters.requisitionUserSelections.length
        && !state.states.hasUnsavedChanges,
    requisitionSampleCount: (state, getters) => getters.requisitionUserSelections.length,
    requisitionAnalysisCount: (state, getters) => {
      const { requisitionUserSelections, sampleTypesAsObject } = getters

      const allAnalysisIDs = requisitionUserSelections.reduce((prev, sample) => {
        prev.push(...sample.analysesIDs)
        return prev
      }, [])

      if (!allAnalysisIDs.length) {
        return null
      }

      let totalCount = 0

      const counts = allAnalysisIDs.reduce((prev, id) => {
        const sampleType = sampleTypesAsObject[id]

        if (!prev[id]) {
          prev[id] = {
            analysisTestGroupID: sampleType.analysisTestGroupID,
            count: 0,
            dkName: mixWBBase(sampleType.translation),
            sampleTypeID: id,
            translation: sampleType.translation,
          }
        }

        prev[id].count += 1
        totalCount += 1

        return prev
      }, {})

      const returnData = {
        ...counts,
        total: totalCount,
      }

      return returnData
    },
    requisitionOptions: (state) => state.options,
    requisitionFileList: (state, getters) => {
      const project = getters.currentScreeningData
      const caseID = project.customCaseNumber || getters.caseNumber

      return project.requisitionFiles.map((x) => {
        const samplesWB = x.sampleCount === 1 ? mixWB('SAMPLE') : mixWB('SAMPLES')

        return {
          name: `${ mixWB('REQUISITION') } - ${ caseID } - ${ x.sampleCount } ${ samplesWB }`,
          status: x.name ? 'uploaded' : 'pending',
          timestamp: x.timestamp,
          type: x.type,
          path: x.path,
        }
      })
    },
    requisitionTableIsUpdating: ({ states }) => states.tableIsUpdating,
    requisitionIsSending: ({ states }) => states.isSending,
    requisitionPickUpText: ({ options }) => {
      if (!options.laboratory) {
        return ''
      }

      return mixWB('REQUISITION_PICKUP_NOTICE', [
        mixWB(options.laboratory.translation),
        options.laboratory.phone,
        mixFormatPhoneNumber(options.laboratory.phone),
      ])
    },
  },
}
