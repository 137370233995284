import { db } from '@/firebase/init'
import { DB_LANGUAGES, DB_TRANSLATIONS } from '@/globals/javascript/models/_helper'
import { keys } from '@/globals/javascript/_util/keys'

export const translationStore = {
  state: {
    selectedLanguage: null,
    languages: [],
    translations: {},
    baseTranslations: {},
  },
  mutations: {
    updateLanguages: (state, languages) => {
      state.languages = languages

      // Set selected language
      const savedLocale = window.localStorage.getItem(keys.LS_SAVED_LOCALE)
      const language = languages.find((x) => x.locale === savedLocale)
      state.selectedLanguage = language || languages.find((x) => x.locale === 'da-DK')
      window.localStorage.setItem(keys.LS_SAVED_LOCALE, state.selectedLanguage.locale)
    },
    updateTranslations: (state, translations) => {
      state.translations = translations
    },
    updateBaseTranslations: (state, translations) => {
      state.baseTranslations = translations
    },
  },
  actions: {
    async getLanguages({ getters, commit }) {
      if (getters.languagesLoaded) {
        return
      }

      const snapshot = await db.collection(DB_LANGUAGES).get()
      const languages = snapshot.docs.map((doc) => ({
        ...doc.data(),
      }))
      commit('updateLanguages', languages)
    },
    async getTranslations({ getters, commit }) {
      if (getters.translationsLoaded) {
        return
      }

      const { locale } = getters.selectedLanguage
      const promises = []

      promises.push(db
        .collection(DB_TRANSLATIONS)
        .doc(locale)
        .get())

      if (locale !== 'da-DK') {
        promises.push(db
          .collection(DB_TRANSLATIONS)
          .doc('da-DK')
          .get())
      }

      const [translations, baseTranslations] = await Promise.all(promises)

      commit('updateTranslations', translations.data())

      if (baseTranslations) {
        commit('updateBaseTranslations', baseTranslations.data())
      }
    },
  },
  getters: {
    // Loaders
    languagesLoaded: (state) => !!state.languages.length,
    translationsLoaded: (state) => !!Object.keys(state.translations).length,

    // Other
    selectedLanguage: (state) => state.selectedLanguage,
    languages: (state) => state.languages.filter((x) => x.isActive),
    translations: (state) => state.translations,
    baseTranslations: (state) => {
      const { selectedLanguage, baseTranslations, translations } = state
      return selectedLanguage.locale === 'da-DK' ? translations : baseTranslations
    },
  },
}
