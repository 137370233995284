function queryAllByClass(className, element) {
  const context = element || document
  return [].slice.call(context.getElementsByClassName(className))
}

function queryByClass(className, element) {
  const result = queryAllByClass(className, element)
  return result.length ? result[0] : undefined
}

// Get the first element up the tree with a chosen class
function getClosestByClass(className, elem) {
  for (
    ;
    elem && elem !== document && elem.nodeType === 1;
    elem = elem.parentNode
  ) {
    if (elem.classList.contains(className)) return elem
  }

  return null
}

// Get the first element up the tree with chosen slector
function getClosest(selector, elem) {
  const firstChar = selector.charAt(0)

  // Get closest match
  for (
    ;
    elem && elem !== document && elem.nodeType === 1;
    elem = elem.parentNode
  ) {
    // If selector is a class
    if (firstChar === '.') {
      if (elem.classList.contains(selector.substr(1))) return elem
    }

    // If selector is an ID
    if (firstChar === '#') if (elem.id === selector.substr(1)) return elem

    // If selector is a tag
    if (elem.tagName.toLowerCase() === selector) return elem
  }
  return null
}

export {
  queryByClass, queryAllByClass, getClosestByClass, getClosest,
}
