export const globalMenu = {
  state: {
    isGlobalMenuShowing: false,
    globalMenuComponent: null,
    globalMenuProps: null,
    globalMenuDataToComponent: null,
  },
  mutations: {
    openGlobalMenu: (state) => {
      state.isGlobalMenuShowing = true
    },
    closeGlobalMenu: (state) => {
      state.isGlobalMenuShowing = false
    },
    setGlobalMenuComponent: (state, component) => {
      state.globalMenuComponent = component
    },
    setGlobalMenuProps: (state, data) => {
      state.globalMenuProps = data
    },
    setGlobalMenuDataToComponent: (state, data) => {
      state.globalMenuDataToComponent = data
    },
  },
  actions: {
    openGlobalMenu: ({ commit }) => {
      commit('openGlobalMenu')
    },
    closeGlobalMenu: ({ commit }) => {
      commit('closeGlobalMenu')
    },
    setGlobalMenuComponent: ({ commit }, component) => {
      commit('setGlobalMenuComponent', component)
    },
    setGlobalMenuProps: ({ commit }, data) => {
      commit('setGlobalMenuProps', data)
    },
    setGlobalMenuDataToComponent: ({ commit }, data) => {
      commit('setGlobalMenuDataToComponent', data)
    },
  },
  getters: {
    isGlobalMenuShowing: (state) => state.isGlobalMenuShowing,
    globalMenuComponent: (state) => state.globalMenuComponent,
    globalMenuProps: (state) => state.globalMenuProps,
    globalMenuDataToComponent: (state) => state.globalMenuDataToComponent,
  },
}
